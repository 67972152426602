import React from "react"

import ProofOfPaymentInvalid from "components/ProofOfPayment/ProofOfPaymentInvalid"
import Layout from "layout/Layout"

export default ({ location }) => {
  return (
    <Layout title="Your order is being processed" seoTitle="Order processing">
      <ProofOfPaymentInvalid location={location} />
    </Layout>
)}